import { NextRouter } from 'next/router';

export enum PagesUrls {
  HOME = '/',
  LOGIN = '/login',
  FORGOT_PASSWORD = '/forgot-password',
  LEGALS = '/legals',
  RULES = '/normas-de-la-comunidad',
  TRUCO_APP = '/pages/truco-app',
  PLAY_REGISTER = '/register',
  PLAY_GUEST = '/jugar-sin-registrarse',
  RANKINGS = '/rankings',
  BASE_URL = 'https://trucogame.com',
}
export enum ExternalUrls {
  GOOGLE_PLAY_APP = 'https://play.google.com/store/apps/details?id=com.cloudymedia.trucowebview',
  SUPPORT = 'https://www.facebook.com/elgrantruco',
  CHAT_SUPPORT = 'https://m.me/elgrantruco',
}

export const isActivePage = (pageName: string, router: NextRouter) =>
  router.pathname === pageName;
